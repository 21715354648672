<template>
  <a-drawer
    :title="$t('msg.log.title')"
    placement="right"
    :closable="true"
    :visible="visible"
    :width="600"
    @close="() => $emit('close')">
    <a-timeline
      :reverse="true">
      <template v-for="entry in entries">
        <!-- Type :: Promote -->
        <a-timeline-item
          :key="entry.id"
          color="blue"
          v-if="entry.metadata.type == 'PROMOTE'">
          <!-- Status -->
          <h5>
            {{ $t(`enum.status.${state(entry)}`) }}
          </h5>
          <!-- Timestamp -->
          <div class="font-size-14 text-secondary mb-2">
            {{ entry.created | calendar }}
          </div>
        </a-timeline-item>
        <!-- Type :: Update -->
        <a-timeline-item
          :key="entry.id"
          color="orange"
          v-else-if="entry.metadata.type == 'UPDATE'">
          <!-- Status -->
          <h5>
            {{ $t('edited') }}
          </h5>
          <!-- Timestamp -->
          <div class="font-size-14 text-secondary mb-2">
            {{ entry.created | calendar }}
          </div>
          <a-descriptions bordered size="small" :column="1">
            <!-- Property -->
            <a-descriptions-item :label="$t('property')">
                <span class="text-dark text-monospace">{{ entry.metadata.property }}</span>
            </a-descriptions-item>
            <!-- Old Value -->
            <a-descriptions-item :label="$t('before')">
              <del class="text-dark">{{ entry.metadata.old_value }}</del>
            </a-descriptions-item>
            <!-- New Value -->
            <a-descriptions-item :label="$t('now')">
              <span class="text-dark">{{ entry.metadata.new_value }}</span>
            </a-descriptions-item>
            <!-- Author -->
            <a-descriptions-item :label="$t('author')">
              <span class="text-dark">{{ entry.created_by }}</span>
            </a-descriptions-item>
          </a-descriptions>
        </a-timeline-item>
      </template>
    </a-timeline>
  </a-drawer>
</template>
<script>
import { status } from '@/utils/styles'

export default {
  props: {
    entries: Array,
    visible: Boolean,
  },
  data: function () {
    return {
      status,
    }
  },
  methods: {
    state(entry) {
      return entry.metadata.sub_status || entry.metadata.status
    },
  },
}
</script>
