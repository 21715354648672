<template>
  <a-icon
    v-if="checked"
    type="check-circle"
    class="text-success" />
  <a-icon
    v-else
    type="close-circle"
    class="text-danger" />
</template>
<script>
export default {
  props: {
    checked: Boolean,
  },
}
</script>
