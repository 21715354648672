<template>
  <div class="card">
    <div class="card-header d-flex align-items-center">
      <h5 class="mt-2 mr-auto text-dark">
        <a-avatar src="/resources/images/platforms/MEN.png" :size="32"/>
        {{ $t('msg.shipment.flex.title') }}
      </h5>
      <a :href="`https://jipink.s3.us-west-2.amazonaws.com/lms/flex-${shipment.external}.pdf`" target="_blank">
        <a-button
          shape="circle"
          icon="qrcode" />
      </a>
    </div>
    <div class="card-body">
      <div class="mb-2">
        <j-check :checked="picked" class="mr-1" />
        {{ $t('msg.shipment.flex.picked') }}
      </div>
      <div class="mb-2">
        <j-check :checked="marked" class="mr-1" />
        {{ $t('msg.shipment.flex.marked') }}
      </div>
      <div class="mb-2">
        <j-check :checked="delivered" class="mr-1" />
        {{ $t('msg.shipment.flex.delivered') }}
      </div>
    </div>
  </div>
</template>
<script>
import JCheck from '@/views/shared/labels/check'
export default {
  props: {
    shipment: Object,
  },
  components: {
    JCheck,
  },
  computed: {
    picked() {
      return this.shipment.tags.includes('FLEX_PICKED')
    },
    marked() {
      return this.shipment.tags.includes('FLEX_MARKED')
    },
    delivered() {
      return this.shipment.tags.includes('FLEX_DELIVERED')
    },
  },
}
</script>
