<template>
  <div class="card">
    <div class="card-body">
      <a-result
        status="error"
        :title="$t('msg.shipment.blocked.title')"
        :sub-title="$t('msg.shipment.blocked.subtitle')">
        <template #icon>
          <a-icon
            type="lock"
            theme="twoTone"
            two-tone-color="#DC143C" />
        </template>
        <template #extra>
          <a-space>
            <a-button
              icon="reload"
              key="console"
              type="danger"
              size="large"
              @click="onContinue">
              {{ $t('msg.shipment.action.unlock') }}
            </a-button>
            <a-button
              icon="form"
              type="link"
              key="buy"
              size="large"
              @click="onEdit">
              {{ $t('msg.shipment.action.edit') }}
            </a-button>
          </a-space>
        </template>
        <div class="text-center">
          <h4>
            {{ $t(`msg.issue.${shipment.issue}`) }}
          </h4>
        </div>
      </a-result>
    </div>
  </div>
</template>
<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import { Modal } from 'ant-design-vue'
export default {
  props: {
    shipment: Object,
  },
  data() {
    return {
      acl,
    }
  },
  methods: {
    onContinue() {
      Modal.confirm({
        title: this.$t('msg.shipment.blocked.unlock.title'),
        content: this.$t('msg.shipment.blocked.unlock.subtitle'),
        okText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        okType: 'danger',
        onOk: () => {
          lms.shipment.unblock(this.shipment.id)
            .then(r => this.$emit('update', this.shipment))
        },
      })
    },
    onEdit() {
      this.$router.push(`/shipments/${this.shipment.id}/update`)
    },
  },
}
</script>
<style scoped>
.desc p {
  margin-bottom: 1em;
}
</style>
