<template>
  <l-map style="height: 300px"
    :options="options"
    :zoom="zoom"
    :center="location">
    <l-control-zoom position="topright" />
    <l-tile-layer :url="url" :attribution="attribution" />
    <!-- GEO -->
    <l-marker v-if="located" :lat-lng="location" />
    <!-- Events -->
    <template v-for="event in events">
      <template v-if="event.lat && event.lng">
        <l-circle-marker
          :key="event.id"
          :lat-lng="[event.lat, event.lng]"
          :color="status[state(event)].preset"
          :radius="6">
          <l-tooltip
            :options="{ direction: 'top', offset: [0,-10] }">
            {{ $t(`enum.status.${state(event)}`) }}
          </l-tooltip>
        </l-circle-marker>
      </template>
    </template>
  </l-map>
</template>
<script>
import { status } from '@/utils/styles'
import { Icon } from 'leaflet'
import {
  LMap,
  LTileLayer,
  LTooltip,
  LMarker,
  LCircleMarker,
  LControlZoom,
} from 'vue2-leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const styles = {
  default: 'ckdi68va30a531iun36gnk6le',
  dark: 'cl49h9liu000014te44d2f7up',
}

export default {
  props: {
    shipment: Object,
    events: Array,
  },
  components: {
    LMap,
    LTileLayer,
    LTooltip,
    LMarker,
    LCircleMarker,
    LControlZoom,
  },
  data () {
    return {
      status,
      attribution: '&copy; Jipink Desk',
      options: {
        zoomControl: false,
        scrollWheelZoom: false,
      },
    }
  },
  computed: {
    zoom() {
      return this.located ? 15 : 10
    },
    located() {
      return this.shipment.destination.address.lat &&
             this.shipment.destination.address.lng
    },
    location() {
      return [
        this.shipment.destination.address.lat || -34.603722,
        this.shipment.destination.address.lng || -58.381592,
      ]
    },
    theme() {
      return this.$store.state.settings.theme
    },
    url() {
      const token = process.env.VUE_APP_MAPBOX_TOKEN
      const retina = typeof window !== 'undefined' && window.devicePixelRatio >= 2 ? '@2x' : ''
      return `https://api.mapbox.com/styles/v1/ppallocchi/${styles[this.theme]}/tiles/256/{z}/{x}/{y}${retina}?access_token=${token}`
    },
  },
  methods: {
    state(event) {
      return event.sub_status || event.status
    },
  },
}
</script>

<style lang="scss">
@import '@/components/mixins.scss';
.leaflet-container{
  background-color: $white;
}

[data-kit-theme='dark'] {
  .leaflet-container{
    background-color: $dark-gray-5;
    border: 1px solid $dark-gray-4;
  }
  .leaflet-control {
    &-attribution {
      color: $secondary;
      background-color: $dark-gray-5;
      a {
        color: $secondary;
      }
    }
    &-zoom {
      border: none;
      &-in,
      &-out,
      &-in:hover,
      &-out:hover {
        color: $white;
        border-color: $dark-gray-5;
        background-color: $dark-gray-4;
      }
    }
  }
}
</style>
