<template>
  <div class="main" v-if="shipment !== undefined">
    <router-link to="/shipments">
      <a-button
        class="p-0 mb-3"
        type="link"
        icon="arrow-left"
        size="large">
        {{ $t('back') }} {{ $t('to') }} {{ $t('shipments') }}
      </a-button>
    </router-link>
    <div class="d-flex flex-wrap align-items-center mb-4">

      <!-- Header -->

      <div class="mr-auto mb-3">
        <!-- Code -->
        <div>
          <span class="user-select-all text-monospace text-dark font-weight-bold font-size-48 align-middle mr-3">{{ shipment.code }}</span>
        </div>
        <div>
          <!-- Ref -->
          <div v-if="shipment.ref">
            {{ $t('ref') }}:
            <a-tag class="text-monospace user-select-all">
              {{ shipment.ref }}
            </a-tag>
          </div>
          <!-- Outsource -->
          <div v-if="shipment.outsource && $auth.granted(acl.shipments.monitor)" class="mt-3">
            {{ $t('outsource') }}:
            <a-tag class="text-monospace user-select-all">
              {{ shipment.outsource }}
            </a-tag>
            <a :href="`https://www.correoargentino.com.ar/formularios/e-commerce?id=${shipment.outsource}`" target="_blank">
              <i class="fe fe-external-link text-primary" />
            </a>
          </div>
        </div>
        <!-- Open Log -->
        <a-button
          shape="circle"
          class="mt-2"
          type="link"
          @click="onLogOpen"
          v-if="$auth.granted(acl.shipments.monitor)">
          {{ $t('updated') }} {{ shipment.updated | elapsed }}.
        </a-button>
      </div>

      <!-- Actions -->

      <j-actions
        :shipment="shipment"
        @update="onUpdate" />

    </div>
    <div class="row">

      <!-- Map -->

      <div class="col-xl-12 mb-4">
        <j-map :shipment="shipment" :events="events" />
      </div>

      <!-- Blocked -->

      <template v-if="shipment.sub_status === 'BLOCKED'">
        <div class="col-xl-12 my-4">
          <j-blocked :shipment="shipment" @update="onUpdate" />
        </div>
      </template>

      <!-- Point: Origin -->

      <div class="col-xl-6 col-lg-12">
        <j-point
          :title="$t('origin')"
          :point="shipment.origin">
          <template #icon>
            <span class="icon-dot font-size-18">⦿</span>
          </template>
        </j-point>
      </div>

      <!-- Point: Destination -->

      <div class="col-xl-6 col-lg-12">
        <j-point
          icon="login"
          :title="$t('destination')"
          :point="shipment.destination">
          <template #icon>
            <span class="icon-pin fe fe-map-pin font-size-16 text-danger" />
          </template>
        </j-point>
      </div>

      <!-- Flex Info -->

      <template v-if="shipment.platform === 'MLI' && $auth.granted(acl.shipments.monitor)">
        <div class="col-lg-12">
          <j-flex :shipment="shipment" />
        </div>
      </template>

      <!-- Notes -->

      <template v-if="shipment.notes">
        <div class="col-12 mb-4">
          <blockquote class="blockquote border border-gray-4">
            <p class="text-uppercase text-muted">
              <a-icon type="paper-clip" />
              {{ $t('notes') }}
            </p>
            <p class="text-dark">
              {{ shipment.notes }}
            </p>
          </blockquote>
        </div>
      </template>

      <!-- Events -->

      <template v-if="events.length">
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex align-items-center">
              <h5 class="mt-2 mr-auto text-dark">
                <a-icon type="qrcode" class="mr-1 text-primary" />
                {{ $t('tracking') }}
              </h5>
              <!-- Copy Link -->
              <a-button
                shape="circle"
                icon="link"
                @click="onLinkCopy" />
            </div>
            <div class="card-body">
              <a-timeline :reverse="true">
                <template v-for="event in events">
                  <!-- Action -->
                  <a-timeline-item
                    :key="event.id"
                    position="left"
                    v-if="event.action">
                    <!-- Icon -->
                    <a-icon slot="dot" type="scan" />
                    <!-- Detail -->
                    <span class="px-1">
                      {{ $t(`msg.action.${event.action}`) }}
                    </span>
                    <!-- Author -->
                    <a-popover
                      trigger="click"
                      placement="right"
                      role="button"
                      v-if="$auth.granted(acl.shipments.monitor)">
                      <template slot="title">
                        <span>
                          {{ $t('author') }}
                        </span>
                      </template>
                      <template slot="content">
                        <p class="text-dark">
                          {{ event.created_by }}
                        </p>
                      </template>
                      <a-icon
                        type="question-circle"
                        class="text-primary"
                        :title="event.created_by" />
                    </a-popover>
                  </a-timeline-item>
                  <!-- Status -->
                  <a-timeline-item
                    :key="event.id"
                    :color="status[state(event)].preset">
                    <!-- Flag -->
                    <a-icon slot="dot"
                      type="delete"
                      class="text-default"
                      v-if="event.flag === 'ERROR'" />
                    <a-icon slot="dot"
                      type="flag"
                      class="text-danger"
                      v-if="event.flag === 'FRAUD'" />
                    <!-- Timestamp -->
                    <div class="font-size-14 text-secondary mb-1">
                      {{ event.date | calendar | capital }}
                    </div>
                    <!-- Status -->
                    <h5 v-if="event.flag === 'ERROR'">
                      <del>{{ $t(`enum.status.${state(event)}`) }}</del>
                    </h5>
                    <h4 v-else>
                      {{ $t(`enum.status.${state(event)}`) }}
                    </h4>
                    <div>
                      {{ $t(`msg.status.${state(event)}`) }}.
                    </div>
                    <div class="mt-2">
                      <!-- Issue -->
                      <div v-if="event.issue">
                        <a-icon class="mr-1 text-primary" type="alert" />
                        {{ $t(`msg.issue.${event.issue}`) }}.
                      </div>
                      <!-- Notes -->
                      <div v-if="event.notes && $auth.granted(acl.shipments.monitor)">
                        <a-icon class="mr-1 text-primary" type="message" />
                        {{ event.notes | capital }}
                      </div>
                      <!-- Flag -->
                      <div v-if="event.flag && $auth.granted(acl.shipments.monitor)">
                        <a-icon class="mr-1 text-primary" type="question-circle" />
                        {{ $t(`msg.flag.${event.flag}`) }}.
                      </div>
                      <!-- Receiver -->
                      <div v-if="event.receiver">
                        <a-icon class="mr-1 text-primary" type="idcard" />
                        {{event.receiver.name}}
                        (DNI: {{event.receiver.identification}})
                      </div>
                      <!-- Updated By -->
                      <div v-if="event.updated_by && $auth.granted(acl.shipments.monitor)">
                        <a-icon class="mr-1 text-primary" type="edit"/>
                        {{ $t('msg.shipment.updatedBy') }} <span class="font-size-12">{{ event.updated_by }}</span>
                      </div>
                      <!-- Courier -->
                      <div class="mt-1">
                        <j-courier :value="event.courier" />
                      </div>
                    </div>
                  </a-timeline-item>
                </template>
              </a-timeline>
            </div>
          </div>
        </div>
      </template>

      <!-- Links -->

      <template v-if="links.length">
        <div class="col-12">
          <h5 class="mb-3">
            {{ $t('msg.shipment.related.title') }}
          </h5>
          <p>
            {{ $t('msg.shipment.related.detail') }}
          </p>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <a-list
                :data-source="links"
                :pagination="false">
                <a-list-item slot="renderItem" slot-scope="link">
                  <j-shipment :shipment="link">
                    <template #cta>
                      <router-link :to="`/shipments/${link.code}/detail`">
                        <a-button icon="eye" />
                      </router-link>
                    </template>
                  </j-shipment>
                </a-list-item>
              </a-list>
            </div>
          </div>
        </div>
      </template>

      <!-- Log -->

      <j-log
        :entries="log"
        :visible="log != null"
        @close="log = null" />

    </div>
  </div>
</template>
<script>
import acl from '@/auth/acl'
import lms from '@/api/lms'
import { status, direction } from '@/utils/styles'
import JShipment from '@/views/shared/items/shipment'
import JCourier from '@/views/shared/labels/courier'
import JMap from './map'
import JPoint from '@/views/shared/cards/point'
import JFlex from './flex'
import JActions from './actions'
import JBlocked from './blocked'
import JLog from './log'

export default {
  components: {
    JShipment,
    JCourier,
    JPoint,
    JFlex,
    JActions,
    JBlocked,
    JMap,
    JLog,
  },
  data: function () {
    return {
      acl,
      status,
      direction,
      shipment: undefined,
      twin: undefined,
      log: undefined,
      events: [],
      links: [],
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      lms.shipment.fetchDetail(this.$route.params.shipment)
        .then(response => (this.shipment = response.data))
      lms.shipment.fetchLinks(this.$route.params.shipment)
        .then(response => (this.links = response.data))
      if (this.$auth.granted(acl.shipments.track)) {
        lms.shipment.fetchEvents(this.$route.params.shipment)
          .then(response => response.data.sort((a, b) => a.date.localeCompare(b.date)))
          .then(events => (this.events = events))
      }
    },
    state(event) {
      return event.sub_status || event.status
    },
    onUpdate() {
      this.fetch()
    },
    onLinkCopy() {
      navigator.clipboard.writeText(this.shipment.url)
      this.$message.success(this.$t('msg.copied'))
    },
    onLogOpen() {
      lms.shipment.fetchLog(this.$route.params.shipment)
        .then(response => {
          if (response.data.length) {
            this.log = response.data
          } else {
            this.$message.info(this.$t('msg.log.empty'))
          }
        })
    },
  },
}
</script>
<style lang="less" scoped>
  .main {
    max-width: 1000px;
    margin: 0 auto;
  }
  .icon-dot {
    color: #4b7cf3;
  }
</style>
